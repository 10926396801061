class Socket{
  constructor(url){
    this.url = url
    this.ws = undefined
  }
  connect(payload,successCallback, failCallback,closeCallback, callback){
    
    if ("WebSocket" in window) {
      this.ws = new WebSocket(this.url);

      this.ws.onopen = ()=> {
        this.send(payload).then(()=>{

                  // 监听消息
          successCallback && this.onMessage(successCallback)
          this.onError(failCallback)
          this.onClose(closeCallback);
          callback()
        })


      };
      

      
    }else{
      console.error('websocket Error')
      failCallback && failCallback('connect error')
    }
  }


  // 监听消息
  onMessage(callback){
    this.ws.onmessage = (evt) =>{
    callback && callback(evt)
    }
  }

  onClose(callback){

    this.ws.onclose = ()=>{
      console.log('close')
      callback && callback({type:"close"})
      // this.send({type:'close',origin: "web"})
    }
    
  }

  onError(callback){
    this.ws.onerror = (err) => {
      callback && callback(err)
    }
  }

  send(msg){
    return new Promise((resolve, reject) => {
      msg.userId = localStorage.getItem('token')
      try {
        this.ws.send(JSON.stringify(msg));
        resolve();
      } catch (err) {
        reject(err);
      }
    });
    
  }

}

export {
  Socket
}