<template>
  <div class="container-tip" v-if="visible">

    <div style="position: relative;top: 50%;left: 50%;transform: translate(-50%, -50%);">
      <img style="width: 100%;" src="@/assets/mobile/tips.png" alt="" srcset="">
      <div class="content">
        <!-- <img style="width: 10rem;" src="@/assets/mobile/continue.png" alt="" srcset=""> -->
        <div class="text">还差{{ count }}次互动就达成目标啦！ <br>快来一起互动吧！ </div>
        <div class="flex justify-center" style="padding: 0 10rem;position: absolute;bottom: 5rem;width: 100%;">
          <div class="sure" @click="leave">确定离开</div>
          <div class="continue" @click="close">继续对话</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>


import { putAction, download, getAction } from '@/request/http.js'
import request from '../../../request/request';

export default {
  data() {
    return {
      visible:false,
      count:5
    }
  },
  mounted() {


  },
  methods: {

    leave(){
      this.$router.replace('/')
    },

    close(){
      this.visible = false
    }







  },
  created() {

  },
  components: {

  }
}
</script>

<style scoped>
.container-tip {
  width: 100vw;
  height: 100vh;
  background: rgb(203 203 203 / 38%);
  position: fixed;
  top: 0;
  left: 0;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  bottom: 12rem;
  font-family: PingFangSCRegular;
  font-weight: 400;
  color: #000000;
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
}
.sure{
  background: linear-gradient(#f8cbad, #fe5f31);
  padding: 1.2rem 3rem;
  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  word-break: keep-all;
  margin: 0 1rem;
}

.continue{
  background: linear-gradient(#ffd388, #ff8632);
  padding: 1.2rem 3rem;
  border-radius: 3rem;
  color: white;
  font-size: 1.5rem;
  word-break: keep-all;
  margin: 0 1rem;
}
</style>