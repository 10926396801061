<template>
  <div class="container">

    <div class="title flex">
      <img :src="require('@/assets/mobile/back.png')" alt="" class="back" @click="back">
      <div class="avatar" style="margin-left: 1.2rem;">
        <img style="width: 100%;" :src="require(`@/assets/mobile/${botType == 'A' ? 'bot1' : 'bot2'}.png`)" alt=""
          srcset="">
      </div>

      <div class="name">{{ bot }}</div>
    </div>

    <div class="content">

      <div class="chat list-scroll" id="chat-record">

        <template v-for="row in chatHistory">
          <div class="message-item-left" v-if="['reply', 'tips', 'url'].includes(row.content_type)">

            <div class="flex">
              <div class="avatar">
                <img style="width: 100%;" :src="require(`@/assets/mobile/${botType == 'A' ? 'bot1' : 'bot2'}.png`)" alt=""
                  srcset="">
              </div>
              <div class="message">
                <div v-html="row.content" v-if="row.content_type == 'url'"></div>

                <template v-else>{{ row.content }}</template>
                <div class="message-icon"></div>
              </div>

              <!-- <div class="name">{{ bot }}</div>
              <div class="time flex align-center" style="margin-left:3rem">
                <img :src="require('@/assets/mobile/time.png')" alt="" srcset="" style="width: 1.1rem;">
                <div style="margin-left: 0.2rem;line-height: 1.2rem;">{{ row.time }}</div>
              </div> -->
            </div>


            <!-- <div class="message">{{ row.content }}</div> -->

            <div class="recommend" v-if="row.recommend" style="padding-left: 4rem;">
              <div v-for="a, index in row.recommend" :key="a" @click="sendRecommend(index)">{{ a }}</div>
              <!-- <div>HPV疫苗是什么？</div>
              <div>请问HPV疫苗有那些种类？</div>
              <div>怎么预约HPV疫苗？</div> -->
            </div>
          </div>


          <div class="message-item-right" v-if="row.content_type == 'question'">

            <div class="flex" style="position: relative;justify-content: end;width: 100%;">
              <!-- <div class="time flex align-center" style="margin-left:3rem">
                <img :src="require('@/assets/mobile/time.png')" alt="" srcset="" style="width: 1.1rem;">
                <div style="margin-right: 0.2rem;line-height: 1.2rem;">{{ row.time }}</div>
              </div>

              <div class="name" style="margin-right: 1.5rem;">{{ nickname }}</div> -->
              <div class="message-right">
                {{ row.content }}
                <div class="message-icon2"></div>
              </div>
              <img class="avatar" :src="require(`@/assets/${userType}.png`)" alt="" srcset="">

            </div>


            <!-- <div class="message-right">{{ row.content }}</div> -->


          </div>
        </template>

      </div>
      <div class="enter">

        <a-input type="text" style="width: calc(100% - 7rem);width: calc(100% - 7rem);height: 3.8rem;"
          :disabled="spinning" placeholder="请输入HPV疫苗相关问题" v-model="currentInput" @keyup.enter.native="sendToserve" />
        <img style="width: 6rem;height: 3.6rem;" :src="require('@/assets/mobile/send.png')" alt="" srcset=""
          @click="sendToserve">
      </div>
    </div>
    <!-- <a-spin class="spin" :spinning="spinning" v-if="spinning" /> -->
    <tip-modal ref="tip" />
  </div>
</template>
<script>


import { putAction, download, getAction } from '@/request/http.js'
import { Socket } from '../socket';
import tipModal from './tips.vue'

var recommend_arr = [
  "国产疫苗有哪些？",
  "网络上关于HPV疫苗的错误信息如何辨别？",
  "如何预约HPV疫苗接种？",
  "HPV疫苗的主要成分是什么？",
  "HPV疫苗接种后是否还需要定期进行宫颈癌筛查？",
  "哪些地区实施了免费HPV疫苗接种政策？",
  "不同HPV疫苗的适用人群有何不同？"
]
export default {
  data() {
    return {
      chatHistory: [],
      socketInstance: null,
      spinning: false,
      currentInput: '',
      recommend: [],
      nickname: "",
      bot: localStorage.getItem('botType') == 'A' ? '疫苗专家' : '护士小姐',
      botType: localStorage.getItem('botType'),
      userType: ""
    }
  },
  mounted() {


    this.init()

    this.nickname = localStorage.getItem('nickname')

    this.userType = localStorage.getItem('userType')


    let content=document.querySelector('.content')
		content.addEventListener('click',function(e){
			if(e.target.className=='show-more'){
				console.log('e',e)

        let parent = e.target.parentNode

        Array.from(e.target.parentNode.children).forEach(ele=>{
          if (ele.className == 'show-more'){
            ele.style.display='none'
          }
          if (ele.tagName == 'A'){
            ele.style.display='block'
          }
        })
        console.log('parent', parent.children)
			}
		})

  },
  methods: {


    init() {

      this.nickname = localStorage.getItem('nickname')
      this.chatHistory = []
      this.spinning = true;
      getAction('/record/history', { id: localStorage.getItem('topicId'), bot_type: localStorage.getItem('botType') }).then(res => {
        console.log('res', res)
        this.spinning = false;
        if (res.data.length == 0) {
          this.recommend = [
            "接种HPV疫苗安全吗？",
            "网络上关于HPV疫苗的错误信息如何辨别？",
            "如何预约HPV疫苗接种？",
          ]
          this.dealData({
            content_type: "reply",
            content: `${localStorage.getItem('nickname')}，您好！我是HPV疫苗小助手——${localStorage.getItem('botType') == 'A' ? '苗主任' : "小薏姐姐"}，很高兴为您提供关于HPV疫苗的信息，有什么我可以帮助您的吗？`
          })
        } else {
          let payload = res.data.map(ele => {
            ele.time = new Date(ele?.create_time).toLocaleTimeString().split(':').splice(0, 2).join(':')
            return ele
          })
          this.recommend = this.getRandomArrayElements(recommend_arr, 3)

          this.dealData({
            content_type: "reply",
            content: `${localStorage.getItem('nickname')}，您好！我是HPV疫苗小助手——${localStorage.getItem('botType') == 'A' ? '苗主任' : "小薏姐姐"}，很高兴为您提供关于HPV疫苗的信息，有什么我可以帮助您的吗？`
          })


          payload.forEach(ele => {

            this.dealData(ele)
          });
        }

        console.log(this.chatHistory)

        this.$nextTick(() => {
          var container = this.$el.querySelector('#chat-record');
          container.scrollTop = container.scrollHeight;
        })
      })

    },

    dealData(data) {
      if (data?.create_time) {
        data['time'] = new Date(data?.create_time).toLocaleTimeString().split(':').splice(0, 2).join(':')
      } else {
        data['time'] = new Date().toLocaleTimeString().split(':').splice(0, 2).join(':')
      }

      this.chatHistory = this.chatHistory.map(ele => {
        delete ele['recommend']
        return ele
      })

      if (['reply', 'url'].includes(data.content_type)) {
        // let _arr = this.chatHistory.map(ele => {
        //   delete ele['recommend']
        //   return ele
        // })

        this.chatHistory.push({
          ...data,
          recommend: this.recommend
        })

        // console.log("_arr", _arr)
        // this.chatHistory = _arr
      } else {
        this.chatHistory.push(data)
      }
    },

    sendRecommend(index) {
      if (this.spinning) {
        return false
      }
      this.currentInput = this.recommend[index]
      this.recommend = []
      this.dealData({
        type: "question",
        content: this.currentInput
      })
      this.sendToserve()
      this.spinning = true
      // this.recommend.splice(index, 1)

    },

    async sendToserve() {

      if (!this.currentInput) {

        this.$message.warning('请输入内容')

        return false
      }
      this.spinning = true;

      if (!this.socketInstance) {
        await this.connectWebSocket()
      }

      this.socketInstance.send({
        "content": this.currentInput,
        "id": localStorage.getItem('topicId'),
        "type": "msg",
        "botType": localStorage.getItem('botType'),
        "language": localStorage.getItem('language')
      })

      this.status = 'waitReply'
      this.recommend = []
      this.dealData({
        content: this.currentInput,
        content_type: "question"
      })

      this.chatHistory.push({
        content: "正在输入...(请等待3-6秒)",
        content_type: "tips"
      })


      this.$nextTick(() => {
        var container = this.$el.querySelector('#chat-record');
        container.scrollTop = container.scrollHeight;
      })

      this.currentInput = ''


    },

    back() {

      let number = 0;
      this.chatHistory.forEach(ele => {
        if (ele.content_type == 'question') {
          number += 1
        }
      })

      if (number >= 10) {

        this.$router.replace('/')

      } else {

        this.$refs.tip.visible = true;
        this.$nextTick(() => {
          this.$refs.tip.count = 10 - number
        })

      }
    },

    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },



    connectWebSocket() {

      return new Promise(resolve => {

        if (!this.socketInstance) {
          this.socketInstance = new Socket(`${process.env.VUE_APP_SOCKET_BASE_URL}socket/connect`)
          this.socketInstance.connect({
            id: localStorage.getItem('topicId'),
            type: "msg",
            type: "connect"
          }, this.acceptMsg, this.errTip, this.closeTip, () => {

            setTimeout(() => {
              resolve('')
            }, 100);

          })
        }


      })


    },

    errTip(err) {
      console.log('err', err)
      this.socketInstance = null
    },

    closeTip(err) {
      console.log('closeTip', err)
      this.socketInstance = null
    },


    acceptMsg(msg) {

      let data = JSON.parse(msg.data)
      console.log('data', data)
      if (data.type == 'reply') {

        this.currentInput = ''

        this.chatHistory.pop()

        this.dealData(
          {
            content: data.msg,
            content_type: data.type
          }
        )
        // this.chatHistory.push({
        //   content: data.msg,
        //   content_type: data.type
        // })

        this.$nextTick(() => {
          var container = this.$el.querySelector('#chat-record');
          container.scrollTop = container.scrollHeight;
        })
      } else if (data.type == 'recommend') {
        this.spinning = false;
        this.recommend = data.data

        let _chat = this.chatHistory.pop()
        // _chat['recommend'] = this.recommend
        this.dealData(_chat)
      } else if(data.type == 'url') {
        this.dealData(
          {
            content: data.msg,
            content_type: data.type
          }
        )
      }
    },








  },
  created() {

  },
  components: {
    tipModal
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: white;
  /* display: flex; */
  background-image: url('../../../assets/mobile/background.png');
  background-size: 100% 100%;
}

.title {

  font-size: 1.7rem;

  font-weight: 500;
  color: #FFFFFF;
  padding-top: 3rem;
  justify-content: start;
  align-items: center;

}

.back {
  width: 0.7rem;
  margin-left: 2rem;
  height: 1.3rem;
}

.content {
  width: 100%;
  height: calc(100% - 8rem);
  position: absolute;
  bottom: 0;
  background: white;
  border-radius: 2.5rem 2.5rem 0 0;
  overflow: hidden;
}

.chat {
  width: 100%;
  height: calc(100% - 6rem);
  background: white;
  padding: 2rem 1rem;

}

.enter {
  width: 100%;
  height: 6rem;
  /* background: yellow; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.avatar {
  width: 3.4rem;
  border-radius: 50%;
  overflow: hidden;
  height: 3.4rem;
}

.name {
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 1.5rem;
}

.time {
  font-size: 1.2rem;
  font-family: PingFangSCRegular;
  font-weight: 400;
  color: #C3C3C3;
}

.message {
  padding: 1.5rem;
  background: #fc7b5f;
  border-radius: 0.6rem;
  font-size: 1.3rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.6rem;
  letter-spacing: 0.1rem;
  max-width: calc(100% - 4.4rem);
  margin-left: 1rem;
  position: relative;
  word-break: break-all
}

.message-item-left {

  width: 100%;
  margin-bottom: 1rem;
  padding: 0 20% 0 0;

}

.message-item-right {

  width: 100%;
  margin-bottom: 1rem;
  padding: 0 0 0 20%;
  display: flex;
  flex-direction: column;
  align-items: end;

}

.recommend div {
  width: 100%;
  /* height: 4.1rem; */
  padding: 1rem 2rem;
  margin-top: 0.8rem;
  background: #6161611f;
  border-radius: 2rem;
}

/* .recommend div:hover {

background: #12ba104a;


} */

.message-right {
  width: fit-content;
  /* height: 4.1rem; */
  padding: 1rem 2rem;
  /* margin: 0.8rem 0; */
  background: #fed1c6;
  border-radius: 0.6rem;
  color: #EB3814;
  max-width: calc(100% - 4.4rem);
  margin-right: 1rem;
  position: relative;
  word-break: break-all
}

.message-icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.4rem;
  left: -0.5rem;
  background: #fc7b5f;
  transform: rotate(45deg);
}

.message-icon2 {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.4rem;
  right: -0.5rem;
  background: #fed1c6;
  transform: rotate(45deg);
}
</style>

<style>
.message a{
  color: #E0E0E0 !important;
}
.show-more{
  display: block;
  color: #009ad3;
  cursor: pointer;
  margin-top:3px
}
</style>
