<template>
  <div class="container flex justify-between">

    <div class="left flex flex-column align-center justify-evenly" style="border-radius: 1rem;">

      <div class="bot-type" @click="changeBot('A')">

        <div :class="{ 'shadow': true, 'bot-select-shadow2': botType == 'A' }"></div>
        <div class="bot-info">
          <img :src="require('@/assets/mobile/bot1.png')" class="bot-img">
          <div class="bot-type-title" :style="{ 'color': botType == 'A' ? 'white' : 'black' }">疫苗专家</div>
          <div class="bot-type-desc" :style="{ 'color': botType == 'A' ? '#FFFFFF' : '#5E5E5E' }">严谨、精准、透彻</div>
        </div>
        <div class="select-icon" v-if="botType == 'A'">
          <img :src="require('@/assets/pc/select.png')" alt="" srcset="" style="width: 100%;">
        </div>

      </div>

      <div class="bot-type" @click="changeBot('B')">
        <div :class="{ 'shadow': true, 'bot-select-shadow2': botType == 'B' }"></div>
        <div class="bot-info">
          <img :src="require('@/assets/mobile/bot2.png')" class="bot-img">
          <div class="bot-type-title" :style="{ 'color': botType == 'B' ? 'white' : 'black' }">护士小姐</div>
          <div class="bot-type-desc" :style="{ 'color': botType == 'B' ? '#FFFFFF' : '#5E5E5E' }">专业、温柔、亲切</div>
        </div>

        <div class="select-icon" v-if="botType == 'B'">
          <img :src="require('@/assets/pc/select.png')" alt="" srcset="" style="width: 100%;">
        </div>
      </div>

      <span class="back" @click="back">退出聊天</span>
      
      <!-- <img width="80%" :src="require('@/assets/mobile/org3.jpg')" alt="" srcset=""
        @click="toPage('https://www.d24h.hk/')">
      <img width="80%" :src="require('@/assets/mobile/org1.jpg')" alt="" srcset=""
        @click="toPage('https://www.vaccineconfidence.org/')">
      <img width="80%" :src="require('@/assets/mobile/org2.png')" alt="" srcset=""
        @click="toPage('https://www.fudan.edu.cn/')"> -->


      <div style="    width: 80%;padding: 1.1rem;
    background: #f7f7f7;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;">
        <img style="height: 5rem;" :src="require('@/assets/mobile/org1.jpg')" alt="" srcset="">
      </div>

      <img style="width: 80%;" :src="require('@/assets/mobile/org2.png')" @click="toPage('https://www.fudan.edu.cn/')"
        alt="" srcset="">


      <div style="    width: 80%;padding: 1.1rem;
    background: #f7f7f7;
    border-radius: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;">
        <img style="height: 5rem;" :src="require('@/assets/mobile/org3.jpg')" alt="" srcset="">
      </div>





    </div>

    <div class="content">

      <div class="chat list-scroll" id="chat-record">

        <template v-for="row in chatHistory">
          <div class="message-item-left" v-if="['reply', 'tips', 'url'].includes(row.content_type)">

            <div class="flex" style="position: relative;;">
              <div class="avatar">
                <img style="width: 100%;" :src="require(`@/assets/mobile/${botType == 'A' ? 'bot1' : 'bot2'}.png`)" alt=""
                  srcset="">
              </div>
              <div class="message">

                <div v-html="row.content" v-if="row.content_type == 'url'"></div>

                <template v-else>{{ row.content }}</template>
                <div class="message-icon"></div>
              </div>
            </div>



            <div class="recommend flex " v-if="row.recommend" style="padding-left: 4.4rem;flex-wrap: wrap;">
              <div v-for="a, index in row.recommend" :key="a" @click="sendRecommend(index)">{{ a }}</div>

            </div>
          </div>


          <div class="message-item-right" v-if="row.content_type == 'question'">

            <div class="flex" style="position: relative;justify-content: end;">

              <div class="message-right">
                {{ row.content }}
                <div class="message-icon2"></div>
              </div>
              <img class="avatar" :src="require(`@/assets/${userType}.png`)" alt="" srcset="">

            </div>

          </div>
        </template>

      </div>
      <div style="padding: 1.5rem;height: 8rem;">
        <div class="enter">

          <input type="text" style="width: calc(100% - 7rem);border:none" v-model="currentInput" :disabled="spinning"
            placeholder="请输入HPV疫苗相关问题" @keyup.enter="sendToserve" />

          <img style="width: 6rem;height: 3.6rem;" :src="require('@/assets/mobile/send.png')" alt="" srcset=""
            @click="sendToserve">
        </div>
      </div>
    </div>
    <!-- <a-spin class="spin" :spinning="spinning" v-if="spinning" /> -->
    <tip-modal ref="tip" />
  </div>
</template>
<script>
var recommendList = [
  "接种HPV疫苗安全吗？",
  "网络上关于HPV疫苗的错误信息如何辨别？",
  "如何预约HPV疫苗接种？",
]

var recommend_arr = [
  "国产疫苗有哪些？",
  "网络上关于HPV疫苗的错误信息如何辨别？",
  "如何预约HPV疫苗接种？",
  "HPV疫苗的主要成分是什么？",
  "HPV疫苗接种后是否还需要定期进行宫颈癌筛查？",
  "哪些地区实施了免费HPV疫苗接种政策？",
  "不同HPV疫苗的适用人群有何不同？"
]
import { putAction, download, getAction } from '@/request/http.js'
import { Socket } from '../socket';
import tipModal from './tips.vue'


export default {
  data() {
    return {
      chatHistory: [],
      socketInstance: null,
      spinning: false,
      currentInput: '',
      recommend: [],
      nickname: "",
      bot: '',
      botType: "",
      userType: ""

    }
  },
  mounted() {

    this.init()
    this.nickname = localStorage.getItem('nickname')
    this.userType = localStorage.getItem('userType')
    this.getBot()

    // this.chatHistory = [
    //   {
    //     content_type: "reply",
    //     content: "小明，您好！我是HPV疫苗客服，很高兴为您提供关于HPV疫苗的信息，有什么我可以帮助您的吗？",
    //     time: new Date().getTime(),
    //     recommend: this.recommend
    //   }
    // ]

    let content = document.querySelector('.content')
    content.addEventListener('click', function (e) {
      if (e.target.className == 'show-more') {
        console.log('e', e)

        let parent = e.target.parentNode

        Array.from(e.target.parentNode.children).forEach(ele => {
          if (ele.className == 'show-more') {
            ele.style.display = 'none'
          }
          if (ele.tagName == 'A') {
            ele.style.display = 'block'
          }
        })
        console.log('parent', parent.children)
      }
    })



  },
  methods: {

    init() {

      this.nickname = localStorage.getItem('nickname')
      this.chatHistory = []
      this.spinning = true;
      let id = localStorage.getItem('topicId')
      getAction('/record/history', { id: id, bot_type: localStorage.getItem('botType') }).then(res => {
        console.log('res', res)
        this.spinning = false;
        if (res.data.length == 0) {
          this.recommend = JSON.parse(JSON.stringify(recommendList))
          this.dealData({
            content_type: "reply",
            content: `${localStorage.getItem('nickname')}，您好！我是HPV疫苗小助手——${localStorage.getItem('botType') == 'A' ? '苗主任' : "小薏姐姐"}，很高兴为您提供关于HPV疫苗的信息，有什么我可以帮助您的吗？`
          })
        } else {
          let payload = res.data.map(ele => {
            ele.time = new Date(ele?.create_time).toLocaleTimeString().split(':').splice(0, 2).join(':')
            return ele
          })

          this.recommend = this.getRandomArrayElements(recommend_arr, 3)

          this.dealData(
            {
              content_type: "reply",
              content: `${localStorage.getItem('nickname')}，您好！我是HPV疫苗小助手——${localStorage.getItem('botType') == 'A' ? '苗主任' : "小薏姐姐"}，很高兴为您提供关于HPV疫苗的信息，有什么我可以帮助您的吗？`,
              time: new Date().getTime(),

            })


          payload.forEach(ele => {
            this.dealData(ele)
          });
        }

        console.log(this.chatHistory)

        this.$nextTick(() => {
          var container = this.$el.querySelector('#chat-record');
          container.scrollTop = container.scrollHeight;
        })
      })

    },

    dealData(data) {
      if (data?.create_time) {
        data['time'] = new Date(data?.create_time).toLocaleTimeString().split(':').splice(0, 2).join(':')
      } else {
        data['time'] = new Date().toLocaleTimeString().split(':').splice(0, 2).join(':')
      }


      this.chatHistory = this.chatHistory.map(ele => {
        delete ele['recommend']
        return ele
      })

      if (['reply', 'url'].includes(data.content_type)) {


        this.chatHistory.push({
          ...data,
          recommend: this.recommend
        })

        // this.chatHistory = _arr
      } else {
        this.chatHistory.push(data)
      }
    },

    sendRecommend(index) {
      if (this.spinning) {
        return false
      }
      this.currentInput = this.recommend[index]
      this.recommend = []
      this.dealData({
        type: "question",
        content: this.currentInput
      })
      this.sendToserve()
      this.spinning = true
      // this.recommend.splice(index, 1)

    },

    async sendToserve() {

      if (!this.currentInput) {

        this.$message.warning('请输入内容')

        return false
      }
      this.spinning = true;

      if (!this.socketInstance) {
        await this.connectWebSocket()
      }
      this.socketInstance.send({
        "content": this.currentInput,
        "id": localStorage.getItem('topicId'),
        "type": "msg",
        "botType": localStorage.getItem('botType'),
        "language": localStorage.getItem('language')
      })

      this.status = 'waitReply'
      this.recommend = []
      this.dealData({
        content: this.currentInput,
        content_type: "question"
      })

      this.chatHistory.push({
        content: "正在输入...(请等待3-6秒)",
        content_type: "tips"
      })


      this.$nextTick(() => {
        var container = this.$el.querySelector('#chat-record');
        container.scrollTop = container.scrollHeight;
      })

      this.currentInput = ''


    },

    getBot() {
      this.botType = localStorage.getItem('botType')
      this.bot = this.botType == 'A' ? '疫苗专家' : '护士小姐'
    },
    changeBot(e) {

      if (localStorage.getItem('botType') == e || this.spinning) {
        return false;
      } else {
        // this.recommend = JSON.parse(JSON.stringify(recommendList))
        this.recommend = []
        this.botType = e
        this.bot = e == 'A' ? '疫苗专家' : '护士小姐'
        localStorage.setItem('botType', e)
        this.chatHistory = []
        this.init()

        let chatBotList = (localStorage.getItem('botList') || '').split(',')

        if (!chatBotList.includes(e)) {
          chatBotList.push(e)

          localStorage.setItem('botList', chatBotList.join(','))
        }
      }

    },

    back() {

      if (this.spinning) {
        return false
      }
      let number = 0;
      this.chatHistory.forEach(ele => {
        if (ele.content_type == 'question') {
          number += 1
        }
      })

      if (number >= 10) {

        this.$router.replace('/')

      } else {

        this.$refs.tip.visible = true
        this.$nextTick(() => {
          this.$refs.tip.count = 10 - number
        })

      }
    },

    toPage(e) {
      window.open(e)
    },
    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },


    connectWebSocket() {

      return new Promise(resolve => {

        if (!this.socketInstance) {
          this.socketInstance = new Socket(`${process.env.VUE_APP_SOCKET_BASE_URL}socket/connect`)
          this.socketInstance.connect({
            id: localStorage.getItem('topicId'),
            type: "msg",
            type: "connect"
          }, this.acceptMsg, this.errTip, this.closeTip, () => {

            setTimeout(() => {
              resolve('')
            }, 100);

          })
        }


      })


    },

    errTip(err) {
      console.log('err', err)
      this.socketInstance = null
    },

    closeTip(err) {
      console.log('closeTip', err)
      this.socketInstance = null
    },


    acceptMsg(msg) {

      let data = JSON.parse(msg.data)
      console.log('data', data)
      if (data.type == 'reply') {

        this.currentInput = ''

        this.chatHistory.pop()

        this.dealData(
          {
            content: data.msg,
            content_type: data.type
          }
        )

        this.$nextTick(() => {
          var container = this.$el.querySelector('#chat-record');
          container.scrollTop = container.scrollHeight;
        })
      } else if (data.type == 'recommend') {
        this.spinning = false;
        this.recommend = data.data

        let _chat = this.chatHistory.pop()
        // _chat['recommend'] = this.recommend
        this.dealData(_chat)
      } else if (data.type == 'url') {
        this.dealData(
          {
            content: data.msg,
            content_type: data.type
          }
        )
      }
    },








  },
  created() {

  },
  components: {
    tipModal
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-image: url('../../../assets/pc/chat.png');
  background-size: 100% 100%;
  padding: 4rem 3rem;
  justify-content: space-between;
}

.left {
  width: 21rem;
  height: 100%;
  background: #ffffffbf;
}

.content {
  width: 119rem;
  height: 100%;
  bottom: 0;
  background: #ffffffbf;
  border-radius: 1rem;
  overflow: hidden;
}

.chat {
  width: 100%;
  height: calc(100% - 8rem);
  padding: 3rem 2rem 2rem;

}

.enter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  border-radius: 5rem;
  overflow: hidden;
  background: white;
  padding: 0 2rem;
}

.avatar {
  width: 4.4rem;
  border-radius: 50%;
  overflow: hidden;
  height: 4.4rem;
}

.name {
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 1.5rem;
}

.time {
  font-size: 1.2rem;
  font-family: PingFangSCRegular;
  font-weight: 400;
  color: #C3C3C3;
}

.message {
  margin-left: 1rem;
  padding: 1.5rem;
  background: #fc7b5f;
  border-radius: 0.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #FFFFFF;
  /* line-height: 1.6rem; */
  letter-spacing: 0.1rem;
  max-width: calc(100% - 5rem);
  width: fit-content;
  position: relative;
  word-break: break-all
}

.message-item-left {

  width: 100%;
  margin-bottom: 1.8rem;
  padding: 0 20% 0 0;

}

.message-item-right {

  width: 100%;
  margin-bottom: 1.8rem;
  padding: 0 0 0 20%;
  display: flex;
  flex-direction: column;
  align-items: end;

}

.recommend div {

  padding: 1rem 4rem;
  margin-top: 0.8rem;
  background: #ffffff;
  border-radius: 2rem;
  margin-right: 1.8rem;
  cursor: pointer;

}

.recommend div:hover {

  background: #12ba104a;


}

.message-right {
  width: fit-content;
  /* height: 4.1rem; */
  padding: 1rem 2rem;
  /* margin: 0.8rem 0; */
  background: #ff7a6136;
  /* border-radius: 3rem 0 3rem 3rem; */
  border-radius: 0.6rem;
  color: #EB3814;
  margin-right: 1.5rem;
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  position: relative;
  word-break: break-all
}

.bot-type {

  width: 16rem;
  height: 16rem;
  position: relative;
  cursor: pointer;
}

.select-icon {
  position: absolute;
  width: 5rem;
  padding: 0.5rem;
  bottom: 0;
  right: -2rem;
  border-radius: 50%;
  background: white;
}

.bot-type .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2rem;
  background: white;
}

.bot-select-shadow1 {
  transition: transform 0.5s linear;
  transform: rotate(10deg);
  background-color: #ffe0d4 !important
}

.bot-select-shadow2 {
  transition: transform 0.5s linear;
  /* transform: rotate(-10deg); */
  background: linear-gradient(#ffa17f, #ffc5ab) !important;
}

.bot-info {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
}

.img-container {
  position: relative;
  width: 100%;
  height: 50%;
}

.bot-img {
  bottom: 0;
  width: 40%;
}

.bot-type-title {
  font-size: 1.3rem;
  color: black;
  font-weight: 400;
  letter-spacing: 0.1rem;

}

.bot-type-desc {
  font-size: 1rem;
  /* background: rgb(167 167 167 / 22%); */
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
}

.back {
  font-size: 2.3rem;
  font-weight: 500;
  color: #ffffff;
  padding: 1.5rem 4rem;
  background: #fe7a5e;
  border-radius: 6rem;
  cursor: pointer;
}


.text {
  color: #000000;
  font-size: 1.6rem;
  letter-spacing: 1px;
  margin: 3rem 0 1.5rem;
  font-weight: 500;
}

.message-icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.4rem;
  left: -0.5rem;
  background: #fc7b5f;
  transform: rotate(45deg);
}

.message-icon2 {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.4rem;
  right: -0.5rem;
  background: #fed1c6;
  transform: rotate(45deg);
}
</style>

<style>
.message a {
  color: #E0E0E0 !important;
}

.show-more {
  display: block;
  color: #009ad3;
  cursor: pointer;
  margin-top: 3px
}
</style>
