<template>
  <div class="body">

    <mobile-chat v-if="mode == 'mobile'" />
    <pc-chat v-else />
  </div>
</template>
<script>


import mobileChat from './mobile/chat.vue'
import mobileTips from './mobile/tips.vue'

import pcChat from './pc/chat.vue'

export default {
  data() {
    return {
      spinning: true,
      url: "",
      tips: "",
      mode: "pc"

    }
  },
  mounted() {

    window.addEventListener('load', this.setRootFontSize);
    window.addEventListener('resize', this.setRootFontSize);

    let chatBotList = (localStorage.getItem('botList') || '').split(',')

    let bot = localStorage.getItem('botType')

    if (!chatBotList.includes(bot)) {
      chatBotList.push(bot)

      localStorage.setItem('botList', chatBotList.join(','))
    }


  },
  methods: {

    setRootFontSize() {
      let viewportWidth = document.documentElement.clientWidth
      let newFontSize = 10
      if (viewportWidth < 750) {
        newFontSize = viewportWidth / 37.5;
        this.mode = 'mobile'
      } else {
        newFontSize = viewportWidth / 149.4
        this.mode = 'pc'
      }

      document.documentElement.style.fontSize = `${newFontSize}px`;
    },

  },
  created() {
    this.setRootFontSize()
  },
  components: {
    mobileChat,
    mobileTips,
    pcChat
  },
  computed: {

  }
}
</script>

<style scoped></style>